@import "./ui/index";
@import "./variables.scss";

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: $colorLightShade;
}

.grecaptcha-badge,
.grecaptcha-badge * {
  display: none !important;
}

.appSpinner {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

// Push the iframe down so it doesn't overlap with buttons in the app header and block user interaction.
$iframePadding: 24px;
$appHeaderHeight: 32px;

.embeddedWebsite {
  background-color: $colorEmptyShade;
  position: absolute;
  margin-top: $iframePadding;
  left: $iframePadding;
  width: calc(100% - $iframePadding * 2);
  height: calc(100% - $iframePadding * 2);
  border-radius: $sizeXs;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  overflow: hidden;
}

.embeddedWebsite--withHeader {
  margin-top: $appHeaderHeight + $iframePadding;
  height: calc(100vh - $appHeaderHeight - $iframePadding * 2);
}

.embeddedWebsite__image {
  width: 100%;
}

.chatContainer {
  position: absolute;
  z-index: 10;
}
