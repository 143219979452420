@import "../ui/styleUtils/index";

.appHeader {
  position: fixed;
  top: 0;
  width: 100vw;
  padding: $sizeXs $sizeM;
}

.appHeaderLogo {
  margin-right: $sizeS;
}
